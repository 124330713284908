import { isWindows } from "react-device-detect";
import { BsWindowSidebar } from "react-icons/bs";
import { CheckPermission } from "./PageAuth";

const RouteAuth = (role_id, urlSegments) => {
  const urlString = `${urlSegments[0]}/${urlSegments[1]}`;

  /*  if(urlSegments[2]) {
     const firstCharUpperCase  = urlSegments[2].toLowerCase().replace(/^\w/g, c => c.toUpperCase())
     if(!CheckPermission(firstCharUpperCase,"View")){
       return (history.back() ? history.back() : window.location = '/404');
     }
   } */
   // #TODO: hard coded
  switch (urlString) {
    case "app/hr":
      if ([1,2,4,5].includes(role_id)) {
        console.log("HR Route");
        return;
      } else {
        return (history.back() ? history.back() : window.location = '/');
      }
    case "app/users":
      if ([1].includes(role_id)) {
        console.log("User Route");
        return;
      } else {
        return (history.back() ? history.back() : window.location = '/');
      }
    // case "app/patients/dashboard":
    //   if (role_id == 8) {
    //     console.log("Patient Route");
    //     return;
    //   } else {
    //     return (history.back());
    //   }
    // case "app/patients":
    //   if (role_id != 8) {
    //     console.log("Patient Route");
    //     return;
    //   } else {
    //     return (history.back());
    //   }
    case "app/employee":
    case "app/employees":
      // if ([1,2,3,4,5,6,7,8,9,10,11,12,18,19].includes(role_id)) {
      //   console.log("Employee Route");
      //   return;
      // }
      if (role_id != 20) {
        console.log("Employee Route");
        return;
      }
      else {
        return (history.back() ? history.back() : window.location = '/');
      }
    case "app/doctors":
      if ([1,2,13,14,15,16,17,22,23,24].includes(role_id)) {
        console.log("Doctor Route");
        return;
      } else {
        return (history.back() ? history.back() : window.location = '/');
      }
    /* case "app/maintenance":
      if (role_id == 1) {
        console.log("Doctor Route");
        return;
      } else {
        return (history.back() ? history.back() : window.location = '/');
      } */
  }
};

export default RouteAuth;
