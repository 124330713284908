import React, { useEffect, useState } from "react";



import Header from "../includes/Header";
import ComingSoon from "src/view/_public/ComingSoon.jsx";
import { useRouter } from "next/router";
import { signOut, useSession } from "next-auth/react";
import { connect } from "react-redux";
import { updateAppSetup } from "../../redux/actions/main";
import Spinner from "../ui/preloader/Spinner";
import RouteAuth from "../lib/middleware/RouteAuth";
import store from "store2";
import _, { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { KTGet } from "../lib/db";

const restrictedRoutes = {
  isLoggedIn: ["/account/registertest", "/account/logintest"],
  app: ["/app/dashboard", "/app/userstest", "/app/employee/dashboard"],
};

const BaseTemplate = (props) => {
  const router = useRouter();
  const { data: session, status, loading } = useSession();
  const { updateAppSetup, KynaAppData } = props;
  const pathname = router.pathname;
  const urlSegments = pathname.substring(1).split("/");
  const [userRole, setUserRole] = useState({});

  const {  } = useQuery(["Session","UserData", userRole?.result?.id ], () => KTGet("/api/users", {
    id: userRole?.result?.id,
    limit: 1,
    select: 'id',
    include: {},
  }), {
    enabled: router.asPath.startsWith("/app") && !isEmpty(userRole) && window.navigator.onLine,
    staleTime: 1000 * 60 * 10, // 10 mins,
    onSuccess: (data) => {
      if(isEmpty(data?.data?.result)) {
        alert("Your account has been deleted. Please contact your administrator.")
        router.replace("/account/login")
        signOut()
        store.remove("UserSession")
      }
    }
  })

  useEffect(async () => {
    // let existingKynaAppData = KynaAppData;
    // existingKynaAppData.activeNav = router.pathname;

    updateAppSetup({
      id: "route",
      data: {
        urlSegments: urlSegments,
        pathname: router.pathname,
      },
    });

    updateAppSetup({
      id: "activeNav",
      data: router.pathname,
    });

    updateAppSetup({
      id: "urlParams",
      data: router.query,
    });

    // TODO: RESET LOCAL SESSION EVERY RELOAD

    // console.log("BASE TEMPLATE sessionsessionsession", session)
    // try {
    //     const userLocalSession = await setLocalSession(session)
    //     console.log("userLocalSession", userLocalSession)

    //     // console.log("store.get('UserSession')", store.get('UserSession'))

    // } catch (error) {
    //     console.log("Error refresh local session error")
    //     console.log("Error", error)
    // }
    // <===== END OF RESET SESSION

    // console.log("router.query", router.query)
    // console.log("BaseTemplate props", props)
    // console.log("router", router)
    // console.log("router path", router.asPath)

    setUserRole(store.get("UserSession"));
  }, []);
  /*   useEffect(() => {
      const user_role = store.get("UserSession");
      RouteAuth(user_role.user_role_id, urlSegments, router);
    },[]) */

  // Add [] to useEffect to run once only

  // useEffect(() => {

  //     if( urlSegments?.length && urlSegments[0] == "app" ){
  //         setIsApp(true)
  //     }

  // }, [isApp])

  if (urlSegments?.length && urlSegments[0] == "app" && status == "loading") {
    return (
      <>
        <div className="flex h-screen bg-gray-50">
          <div className="m-auto text-center">
            <Spinner isLoading={true} svgClass={"text-gray-900"} />
            {/* <div className="text-gray-500 tracking-widest uppercase text-sm mt-3">Loading...</div> */}
          </div>
        </div>
      </>
    );
  }


  if (
    status === "unauthenticated" &&
    urlSegments[0] == "app"
  ) {
    window.location = "/account/login";
  }

  if (_.isEmpty(userRole) != true) {
    // console.log("User Role" + JSON.stringify(userRole));
    // console.log("User Role", userRole);
    if (userRole?.result?.user_role_id) {
      RouteAuth(userRole.result.user_role_id, urlSegments, router);
    }
  }

  if (
    status === "authenticated" &&
    restrictedRoutes.isLoggedIn.indexOf(router.route) > -1
  ) {
    window.location = "/app/dashboard";
  }

  return (
    <div>
      <Header {...props} />
      {
        process.env.NEXT_PUBLIC_IS_UNDER_MAINTENANCE == 1 ? (
          <ComingSoon />
        ) : (
          <>
            <main>{props?.body ? props.body : props.children}</main>
            <footer>{props?.footer ? props.footer : null}</footer>
          </>
        )
      }
      {/* <main>{props?.body ? props.body : props.children}</main>
      <footer>{props?.footer ? props.footer : null}</footer> */}
    </div>
  );
};

// export default BaseTemplate;

const mapStateToProps = (state) => ({
  KynaAppData: state.mainReducer.KynaAppData,
});

const mapDispatchToProps = {
  updateAppSetup: updateAppSetup,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseTemplate);
