import React from 'react';;
import Head from 'next/head'

const Header = (props) => {
    // console.log("props", props)
    return(
        <Head>
            <title>{props.title}</title>
            <meta name="description" content={ props?.description ? props.description : '' } />
            <meta name="viewport" content="width=device-width,initial-scale=1" />

            <meta property="og:title" content="Philippine Mental Health Association, Inc." />
            <meta
                property="og:description"
                content="PMHA is a private, non-stock, non-profit organization that provides premier Mental Health Services through Education, Advocacy, Intervention and Research."
            />
            <meta
                property="og:image"
                content="https://ucarecdn.com/56a3509d-66f4-4bec-af9c-d7114ee94a98/"
            />
        </Head>
    );
}

export default Header;
